function initScrollHeader(identifier, threshold) {
	var element = $(identifier);
	if (element.length > 0) {
		element.each(function () {

			$(document).scroll(function () {
				if ($(this).scrollTop() >= threshold) {
					element.addClass('has-scrolled');
				} else {
					element.removeClass('has-scrolled');
				}
			});

		});
	}
}

export default initScrollHeader;
