function initModal() {
	$(document).on('click', '.js-open-modal', function (e) {
		var target = $(this).data('target');
		target = $('#' + target);
		$(target).fadeIn(200);
		$('body').addClass('noscroll');
		if ($(this).data('index')) {
			$(target).attr('data-index', $(this).data('index'));
		}
	});
	$(document).on('click', '.js-close-modal', function () {
		$(this).parents('.modal').fadeOut(200);
		$('body').removeClass('noscroll');
	})
	$(document).on('keyup', function (e) {
		if (e.key == "Escape") {
			$('.modal').fadeOut(200);
			$('body').removeClass('noscroll');
		}
	});

}

export default initModal;
