function initLoadMore() {
	if ($('.js-loadmore').length > 0) {
		$(document).on('click', '.js-loadmore', function (e) {
			e.preventDefault();
			var target = $(this).attr('href');
			var button = $(this);
			$.ajax({
				url: target,
				type: 'GET',
				beforeSend: function (xhr) {
					button.html(button.attr('data-loadingtext'));
				},
				success: function (data) {
					if (data) {
						button.html(button.attr('data-moretext'));
						$(data).find('#estates').find('.column').appendTo($('#estates'));
						observer.observe();
						if ($(data).find('#pagination')) {
							$('#pagination').replaceWith($(data).find('#pagination'));
						} else {
							button.remove(); // If no data, remove the button as well
						}
						[].forEach.call(document.querySelectorAll('.js-slider-wrapper'), function (wrapper) {
							createSlider(wrapper);
						});
					}
				}
			});

		})
	}
}

export default initLoadMore;
