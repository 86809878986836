import { mask } from 'jquery-mask-plugin';

function displayLabel() {
	$('.fui-input').each(function (item) {
		if ($(this).val() == '') {
			$(this).closest('.fui-field').find('.fui-label').removeClass('active');
		} else {
			$(this).closest('.fui-field').find('.fui-label').addClass('active');
		}
	});
	$('.fui-input').on('focus', function () {
		$(this).closest('.fui-field').find('.fui-label').addClass('active');
	})
	$('.fui-input').on('focusout', function () {
		if ($(this).val() == '') {
			$(this).closest('.fui-field').find('.fui-label').removeClass('active');
		}
	})
	$('textarea').each(function (item) {
		if ($(this).val() == '') {
			$(this).closest('.fui-field').find('.fui-label').removeClass('active');
		} else {
			$(this).closest('.fui-field').find('.fui-label').addClass('active');
		}
	});
	$('textarea').on('focus', function () {
		$(this).closest('.fui-field').find('.fui-label').addClass('active');
	})
	$('textarea').on('focusout', function () {
		if ($(this).val() == '') {
			$(this).closest('.fui-field').find('.fui-label').removeClass('active');
		}
	})
}

// Fetch all Date fields - specifically the input. Events are bound on the input element
var $fields = document.querySelectorAll('.fui-type-date-time input');

// For each field, bind on the `afterInit` event
$fields.forEach($field => {
	$field.addEventListener('afterInit', (e) => {
		displayLabel();
	});
});

document.addEventListener('onAfterFormieValidate', function (e) {
	console.log('formie after validate');
	gtag_report_conversion(document.location);
})

document.addEventListener('onFormieValidate', function (e) {
	submitHandler = e.detail.submitHandler;
	if (submitHandler.config.formHandle.startsWith('downloadForm')) {
		var plansField = "<ul>";
		if ($("input[type='checkbox'][name='fields[allFiles]']").prop('checked')) {
			plans.forEach(element => {
				plansField += '<li><a href="' + element.url + '">' + (element.name ? element.name : 'File') + '</a></li>';
			});
			plansField += '</ul>';
		} else {
			var index = $("input[name='fields[allFiles]']").closest('.modal').attr('data-index') - 1;
			var plan = plans[index];
			plansField += '<li><a href="' + plan.url + '">' + (plan.name ? plan.name : 'File') + '</a></li>';
			plansField += '</ul>';
		}
		$("input[name='fields[plans]']").val(plansField);
	}

	if (submitHandler.config.formHandle.startsWith('offer') || submitHandler.config.formHandle.startsWith('offre')) {
		var street = $("input[name='fields[street]']").val();
		var number = $("input[name='fields[number]']").val();
		var zip = $("input[name='fields[zip]").val();
		var city = $("input[name='fields[city]").val();
		var addressFull = street + ' ' + number + ' ' + zip + ' ' + city;

		if ($("textarea.fui-input").val() == '') {
			$("textarea").val('n/a')
		}
		$("input[name='fields[fullAddress]']").val(addressFull);

		var date = $('.flatpickr-input');
		$("input[name='fields[dateFormatted]'").val(date.val());
	}
});

document.addEventListener('onFormieInit', (e) => {

	$("input[name='fields[kboNumber]']").on('change', function () {
		var number = $(this).val();
		number = number.split('.').join("");
		if (number != '') {
			GetNameByKBONumber(number);
		}
	});

	if ($("input[name='fields[nomAssociationDesCoproprietaires]']")) {
		$("input[name='fields[nomAssociationDesCoproprietaires]']").attr('readonly', true);
		$("input[name='fields[kboNumber]']").mask("0000.000.000", {
			placeholder: "____.___.___"
		});
	}
});

function gtag_report_conversion(url) {
	var callback = function () {
		if (typeof (url) != 'undefined') {
			window.location = url;
		}
	};
	gtag('event', 'conversion', {
		'send_to': 'AW-761186241/KV23COz0opMYEMGP--oC',
		'event_callback': callback
	});
	return false;
}

function GetNameByKBONumber(number) {
	var data = {
		number: number
	};
	data[window.csrfTokenName] = window.csrfTokenValue;
	$.ajax({
		type: 'POST',
		url: "/actions/kbo-module/kbo-number/validate-number",
		data: data,
		cache: false,
		success: function (response) {
			var id = $("input[name='fields[nomAssociationDesCoproprietaires]']").attr('id');
			if ($("input[name='fields[nomAssociationDesCoproprietaires]']").parents('.fui-field-container').find('.fui-error-message').length) {
				$("input[name='fields[nomAssociationDesCoproprietaires]']").parents('.fui-field-container').find('.fui-error-message').remove();
			}
			if (response) {
				response = jQuery.parseJSON(response);
			}
			if (response && response.name && response.name != '') {
				$('label[for="' + id + '"]').addClass('active');
				$("input[name='fields[nomAssociationDesCoproprietaires]']").val(response.name);
				$("input[name='fields[gebouwcode]']").val(response.code);
			} else {
				$("input[name='fields[nomAssociationDesCoproprietaires]']").val('');
				$("input[name='fields[gebouwcode]']").val('');
				$('label[for="' + id + '"]').removeClass('active');
				var error = $("input[name='fields[nomAssociationDesCoproprietaires]']").data('fui-message');
				var errorDiv = $("<div class='fui-error-message'></div>").text(error); // Create with jQuery
				$("input[name='fields[nomAssociationDesCoproprietaires]']").parents('.fui-field-container').append(errorDiv);
			}
		}
	});
}

export default displayLabel;
