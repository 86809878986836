function initSmoothScroll() {
	// Smooth scrolling when clicking on a anchor link
	const anchors = document.querySelectorAll('a[href*="#"]:not([href="#"]');
	anchors.forEach(function (el) {
		el.addEventListener('click', function (e) {
			if (!document.querySelector(this.hash)) {
				return;
			}
			e.preventDefault();
			smoothScroll(this.hash)
		});
	});

	// scrolling for anchor links coming from a different page
	const hash = window.location.hash;
	if (hash.length > 0) {
		window.scrollTo(0, 0);
		setTimeout(function () {
			smoothScroll(hash, 300)
		}, 100);
	}

}


function smoothScroll(hash) {
	// Get target element
	const target = hash;

	// Calcul scrollTop
	const offsetTop = document.querySelector(target).offsetTop;
	scroll({
		top: offsetTop - 150,
		behavior: 'smooth',
	});
}

export default initSmoothScroll;
