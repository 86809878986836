function initMenu() {

	const header = document.querySelector('.js-site-header');
	const body = document.querySelector('body');
	const scrollChange = 100;

	const addClassOnScroll = function () {
		body.classList.add('scrolled');
		header.classList.add('scrolled');
	}
	const removeClassOnScroll = function () {
		body.classList.remove('scrolled');
		header.classList.remove('scrolled');
	}
	window.addEventListener('scroll', function () {
		scrollpos = window.scrollY;

		if (scrollpos >= scrollChange) { addClassOnScroll() } else { removeClassOnScroll() }
	});

	let lastScrollTop;
	window.addEventListener('scroll', function () {
		var scrollTop = window.scrollY || document.documentElement.scrollTop;
		if (scrollTop > lastScrollTop) {
			header.classList.add('is-hidden');
		}
		else {
			header.classList.remove('is-hidden');
		}
		lastScrollTop = scrollTop;
	});

	$(document).on('click', '.dropdown', function () {
		var current = $(this);
		current.toggleClass('is-active');
	})

	// Open submenu
	const menuLinks = document.querySelectorAll('.js-open-submenu');
	if (menuLinks) {
		menuLinks.forEach(function (m, index) {
			m.onmouseenter = function () {
				if (window.innerWidth >= 1024) {
					this.classList.add('is-active');
				}
			}
			m.onmouseleave = function () {
				if (window.innerWidth >= 1024) {
					this.classList.remove('is-active');
				}
			}
			m.onclick = function (e) {
				if (!this.classList.contains('is-active')) {
					e.preventDefault();
				}
				menuLinks.forEach(function (n, i) {
					if (index != i) {
						n.classList.remove('is-active');
					}
				})
				this.classList.toggle('is-active');
			}
		});
	}

	// Empty menu
	const emptyMenu = document.querySelectorAll('.js-nav a[href="#"]');
	emptyMenu.forEach(function (el) {
		el.addEventListener('click', function (e) {
			e.preventDefault();
		});
	});


	// Click submenu
	// Responsive nav
	const burgerBtn = document.querySelector('.js-menu');
	if (burgerBtn) {
		burgerBtn.addEventListener('click', function () {
			if (!burgerBtn.classList.contains('menu-is-opened')) {
				burgerBtn.classList.add('menu-is-opened');
				burgerBtn.classList.remove('menu-is-closed');
			} else {
				burgerBtn.classList.remove('menu-is-opened');
				burgerBtn.classList.add('menu-is-closed');
			}
			header.classList.toggle('is-opened');
		});
	}
}

export default initMenu;
