function initSearch() {
	jQuery.expr[':'].Contains = function (a, i, m) {
		return jQuery(a).text().toUpperCase()
			.indexOf(m[3].toUpperCase()) >= 0;
	};


	jQuery.expr[':'].contains = function (a, i, m) {
		return jQuery(a).text().toUpperCase()
			.indexOf(m[3].toUpperCase()) >= 0;
	};

	$('#search').keyup(function (e) {
		var s = $(this).val().trim();

		if (s === '') {
			$('#results LI').show();
			return true;
		}
		$('#results LI:not(:contains(' + s + '))').hide();

		$('#results LI:contains(' + s + ')').show();
		return true;
	});
}

export default initSearch;
