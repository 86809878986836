// Add an event listener for filter clicks
function initAjaxFilters() {
	$(document).on('click', '.js-filters-tags .tag', onFilterClick);
	$(document).on('change', '.js-filters-selects', onFilterChange);
	$(document).on('change', '.js-filters-estate', onFilterEstateChange);
	// $(document).on('focusout', '.js-filter-price', onFilterEstateChange);

	$(document).on('click', '.js-open-filters', function (e) {
		if ($('.filters__bottom').hasClass('open')) {
			$(this).text($(this).data('label-open'));
			$('.filters__bottom').slideUp().removeClass('open');
		} else {
			$(this).text($(this).data('label-close'));
			$('.filters__bottom').slideDown().addClass('open');
		}
	});

	if ($('#autoComplete').length > 0) {
		var items = [];
		var placeholder = $('#autoComplete').data('placeholder');
		var lang = $('#autoComplete').data('lang');
		if (lang == 'en') {
			lang = 'fr';
		}
		$.getJSON('/assets/zip-' + lang + '.json', function (data) {
			$.each(data, function (key, val) {
				items.push({
					'name': val.City + ' - ' + val.Zip,
					'city': val.City,
					'zip': val.Zip
				});
			})
		})

		var autoCompleteJS = new autoComplete({
			placeHolder: placeholder,
			data: {
				src: items,
				keys: ["name"],
				cache: true
			},
			query: (query) => {
				// Split query into array
				const querySplit = query.split(",");
				// Get last query value index
				const lastQuery = querySplit.length - 1;
				// Trim new query
				const newQuery = querySplit[lastQuery].trim();

				return newQuery;
			},
			events: {
				input: {
					selection: (event) => {
						const feedback = event.detail;
						const input = autoCompleteJS.input;
						// Trim selected Value
						const selection = feedback.selection.value.city.trim();
						// Split query into array and trim each value
						const query = input.value.split(",").map(item => item.trim());
						// Remove last query
						query.pop();
						// Add selected value
						query.push(selection);
						// Replace Input value with the new query
						input.value = query.join(", ") + ", ";

						$('#autoComplete').val(input.value).trigger('change');
					}
				}
			}
		});
	}

	if ($('#filters-estates').length) {
		// Format number 
		$('.js-filter-price').each(function (e) {
			var input = $(this).val();
			var input = input.replace(/[\D\s\._\-]+/g, "");
			input = input ? parseInt(input, 10) : 0;
			$(this).val(function () {
				return (input === 0) ? 0 : input.toLocaleString("nl-BE");
			});
		})

		$('.js-filter-price').on("keyup", function (event) {

			// When user select text in the document, also abort.
			var selection = window.getSelection().toString();
			if (selection !== '') {
				return;
			}

			// When the arrow keys are pressed, abort.
			if ($.inArray(event.keyCode, [38, 40, 37, 39]) !== -1) {
				return;
			}

			var input = $(this).val();
			var input = input.replace(/[\D\s\._\-]+/g, "");
			input = input ? parseInt(input, 10) : 0;
			$(this).val(function () {
				return (input === 0) ? 0 : input.toLocaleString("nl-BE");
			});


		});
	}
}

// Event handler for the filter click
function onFilterClick(e) {
	e.preventDefault();
	var $filter = $(e.currentTarget);

	if ($(this).hasClass('active')) {
		var href = window.location.href;
		$(this).removeClass('active');
	} else {
		$('.tag').removeClass('active');
		$filter.addClass('active');
		var href = $filter.attr('href');
	}
	$.ajax(href, {
		dataType: 'html',
		beforeSend: function (response) {
			var loader = $('<span class="loader" />');
			$('#results').html(loader);
		},
		success: function (response) {
			refreshProjects(response);
		}
	});
}

// Event handler for the select 
function onFilterChange(e) {
	e.preventDefault();
	var reference_type = $('select[name="reference_type"').val();
	var reference_size = $('select[name="reference_size"').val();
	var reference_location = $('select[name="reference_location"').val();
	$.ajax({
		type: 'GET',
		url: 'ajax/references',
		data: {
			reference_type,
			reference_size,
			reference_location
		},
		beforeSend: function (response) {
			var loader = $('<span class="loader" />');
			$('#results').html(loader);
		},
		success: function (response) {
			refreshProjects(response);
		}
	});
}

function refreshProjects(html) {

	// Update the <title> tag
	document.title = $(html).filter('title').text();

	// Update the #results DOM element with new HTML
	var $html = $('<div />').append(html);
	$('#results').html($html.find('#results').html());

}

function onFilterEstateChange(e) {
	var url = $(this).closest('form').attr('action');
	var arr = $(this).closest('form').serializeArray();
	for (var i = 0; i < arr.length; i++) {
		if (arr[i].name == 'min' || arr[i].name == 'max') {
			arr[i].value = arr[i].value.replace(/[($)\s\._\-]+/g, ''); // Sanitize the values.
		}
	};
	var values = $.param(arr);
	$.ajax({
		url: url + '?' + values,
		beforeSend: function (response) {
			var loader = $('<span class="loader" />');
			$('#results').html(loader);
		},
		success: function (data) {
			window.history.pushState("", document.pageTitle, document.location.origin + document.location.pathname + '?' + values);
			var results = $(data).find('#results');
			var count = $(data).find('.filters__result');
			$('#results').replaceWith(results);
			$('.filters__result').replaceWith(count);
			observer.observe();
			[].forEach.call(document.querySelectorAll('.js-slider-wrapper'), function (wrapper) {
				createSlider(wrapper);
			});
		}
	});
}

export default initAjaxFilters;
