function initCounter() {
	if ($('.js-counter').length > 0) {
		var element = $(".js-counter");
		var page = $(window);

		page.on("scroll resize", function () {
			var pageHeight = page.height();
			var pageScrollTop = page.scrollTop();
			var total = pageScrollTop + pageHeight;

			$.each(element, function (i, el) {
				var current = $(this);
				var currentOuterHeight = current.outerHeight();
				var currentOffsetTop = current.offset().top + 20;

				// Start animation
				if (pageScrollTop <= currentOffsetTop + currentOuterHeight && currentOffsetTop <= total && !current.hasClass('active')) {
					current.addClass('active');
					var initVal = $(el).data('from');
					var lastVal = $(el).data('to');
					animate(el, initVal, lastVal, 1000);
				}
			});
		}).trigger("scroll");
	}
}

function animate(obj, initVal, lastVal, duration) {

	let startTime = null;

	//get the current timestamp and assign it to the currentTime variable
	let currentTime = Date.now();

	//pass the current timestamp to the step function
	const step = (currentTime) => {

		//if the start time is null, assign the current time to startTime
		if (!startTime) {
			startTime = currentTime;
		}

		//calculate the value to be used in calculating the number to be displayed
		const progress = Math.min((currentTime - startTime) / duration, 1);

		//calculate what to be displayed using the value gotten above
		obj.innerHTML = commaSeparateNumber(Math.floor(progress * (lastVal - initVal) + initVal));

		//checking to make sure the counter does not exceed the last value (lastVal)
		if (progress < 1) {
			window.requestAnimationFrame(step);
		}
		else {
			window.cancelAnimationFrame(window.requestAnimationFrame(step));
		}
	};

	//start animating
	window.requestAnimationFrame(step);
}

function commaSeparateNumber(val) {
	while (/(\d+)(\d{3})/.test(val.toString())) {
		val = val.toString().replace(/(\d+)(\d{3})/, '$1' + '.' + '$2');
	}
	return val;
}

export default initCounter;
