import SimpleLightbox from "simplelightbox/dist/simple-lightbox.esm"



function initLightBox() {
	if ($('.js-gallery').length > 0) {
		var lightbox = new SimpleLightbox('.js-gallery a', {
			'navText': ['←', '→'],
			'showCounter': false,
			'closeText': ''
		});
		$('.js-open-lightbox').on('click', function () {
			lightbox.open();
		})
	}
}
export default initLightBox;
