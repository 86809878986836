function openAccordion() {
	var element = $(this);

	if (element.hasClass('open')) {

		element.parents('.accordion').find('.accordion__content').stop().slideToggle(function () {
			element.removeClass('open');

			element.attr('aria-expanded', false);
			element.parents('.accordion').find('.accordion__content').attr('aria-hidden', true);
		});
	} else {
		element.addClass('open');

		element.attr('aria-expanded', true);

		element.parents('.accordion').find('.accordion__content').stop().slideToggle();
		element.parents('.accordion').find('.accordion__content').attr('aria-hidden', false);
	}
}

function initAccordion() {
	$('.accordion').each(function () {
		title = $(this).attr('id');
		if (window.location.hash.slice(1) == title) {
			$(this).find('.accordion__title').addClass('open');
			$(this).find('.accordion__title').attr('aria-expanded', true);
			$(this).find('.accordion__content').attr('aria-hidden', false);
			$(this).find('.accordion__content').stop().slideToggle();
		}
	});


	$(document).on('click', '.accordion__title', openAccordion);
}

export default initAccordion;

