function initPlayer() {
	var videoPlayers = document.querySelectorAll('.js-video-player');

	if (videoPlayers) {
		videoPlayers.forEach((videoPlayer) => {
			var player = new Plyr(videoPlayer, {
				controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen'],
				youtube: {
					noCookie: true,
					rel: 0,
					showinfo: 0,
					iv_load_policy: 3,
					modestbranding: 1
				}
			});
		});

	}
}

export default initPlayer;
